<template>
    <div style="padding:0 .2rem;overflow-x: scroll;width:calc(100vw - .4rem);display: flex;">
        <div v-for="(item,index) in list" class="topic-select">
            <div class="select-top">
                <img :src="item.share_cover"
                     style="border-top-right-radius:.35rem;border-top-left-radius:.35rem;width:100%;height:100%"/>
            </div>
            <div style="flex: 1;padding:.1rem .3rem;display: flex;flex-direction: column;justify-content: center">
                <h4 class="text-resolve1">
                    {{item.title}}
                </h4>
                <p class="text-resolve" style="color:gray">
                    <!--                            {{item.share_desc}}-->
                    基于字体的图标集，可以通过 Icon 组件使用，也可以在其他组件中通过 icon 属性引用。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ArticleSplit",
        data(){
            return{

            }
        },
        props:{
            list:Array
        }
    }
</script>

<style scoped>
    .text-resolve {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text-resolve1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .topic-select {
        flex-shrink: 0;

        background: #fafafa;
        height: 4rem;
        width: 4rem;
        margin: .3rem .2rem .3rem 0;
        font-size: .28rem;
        border-radius: .35rem;
        display: flex;
        flex-direction: column;
    }

    .select-top {
        height: 2.5rem;
        width: 100%;
    }
</style>
