<template>
    <div class="topic" style="font-size:.38rem;">
        <div class="topic-top"></div>
        <div style="padding:.5rem .3rem .3rem .3rem;" class="search">
            <van-button @click="$router.push('search')" icon="search" icon-position="left" block
                        style="border-radius: .3rem;color:gray;text-align: left">
                请输入搜索文章标题
            </van-button>
        </div>
        <!--<div v-if="top_list.length!==0">
            <van-tabs v-model="active">
                <van-tab v-for="item in list_manage" :title="item.name">
                    <div style="background: white">
&lt;!&ndash;                        <h4 style="padding:.2rem .2rem 0;" class="title">&ndash;&gt;
&lt;!&ndash;                            置顶文章&ndash;&gt;
&lt;!&ndash;                        </h4>&ndash;&gt;
                        <article-split :list="item.list"></article-split>
                    </div>
                </van-tab>
            </van-tabs>
        </div>-->
        <div v-if="top_list.length!==0">
            <h4 style="padding:.2rem .2rem 0;" class="title">
                精选文章
            </h4>
            <div style="padding:0 .2rem;overflow-x: scroll;width:calc(100vw - .4rem);display: flex;">
                <div v-for="(item,index) in top_list" @click="turnTo(item.id,index)" class="topic-select">
                    <div class="select-top">
                        <img :src="item.share_cover"
                             style="border-top-right-radius:.2rem;border-top-left-radius:.2rem;width:100%;"/>
                    </div>
                    <div style="width:calc(100% - .6rem);flex:1;background:rgba(0,0,0,.3);color:white;flex: 1;padding:.1rem .3rem;display: flex;flex-direction: column;justify-content: center">
                        <h4 class="text-resolve1">
                            {{item.title}}
                        </h4>
                        <p class="text-resolve" style="color:#eeeeee">
                            {{item.share_desc}}
                            <!--                            基于字体的图标集，可以通过 Icon 组件使用，也可以在其他组件中通过 icon 属性引用。-->
                        </p>
                    </div>
                    <div @click.stop="ensureLove(item)" style="position: absolute;right:.3rem;top:.1rem">
                        <!--                        <van-icon name="like" size=".4rem" :color="bg"/>-->
                        <van-icon name="like" :color="item.is_liked===1?'red':'#dcdcdc'"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="topic-article">
            <h4 style="padding:.2rem 0 .2rem" class="title">
                发现
            </h4>
            <div @click="turnTo(item.id,index)" style="display: flex;flex-direction: row;"
                 v-for="(item,index) in article_list" :key="index">
                <div class="topic-card"
                     style="font-size:.28rem;width:100%;display: flex;padding:.2rem .2rem;">
                    <div style="width:1.7rem;height:1.7rem;margin-right: .2rem;position: relative">
                        <img :src="item.share_cover"
                             style="top:0;width:100%;height:1.7rem;border-radius: .2rem;position: absolute"/>
                    </div>
                    <div style="flex:1;display: flex;flex-direction: column;justify-content: center">
                        <h4 class="text-resolve1" style="width: calc(100vw - 3.2rem);">
                            {{item.title}}
                        </h4>
                        <div class="text-resolve" style="margin: .05rem 0;">
                            {{item.share_desc}}
                            <!--                            基于字体的图标集，可以通过 Icon 组件使用，也可以在其他组件中通过 icon 属性引用。-->
                        </div>
                        <div style="color:#afafaf;font-size: .28rem;">
                            {{item.created_at.split(' ')[0]}}
                        </div>
                    </div>
                    <div @click.stop="ensureLove(item)">
                        <!--                        <van-icon name="like" size=".4rem" :color="bg"/>-->
                        <van-icon name="like" :color="item.is_liked===1?'red':'#dcdcdc'"/>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Toast from 'vant/lib/toast'
    import ArticleSplit from "./ArticleSplit";

    export default {
        name: "Article",
        components: {ArticleSplit},
        data() {
            return {
                even: '',

                bg: '#dcdcdc',
                article_list: [],
                page: 0,

                list_manage: [],
                love_list: [],
                top_list: [],
                active: 0,//'标签页'
            }
        },
        created() {
            this.articleList('');
            this.$axios.get('miniprogram/articleTopList?page=1&size=3').then((res) => {
                if (res.data.code === '0000') {
                    this.$nextTick(() => {
                        this.top_list = res.data.content.integral_articles;
                        this.over('置顶文章', this.top_list);
                    })


                }
            })
            this.$axios.get('miniprogram/articleLikeList?page=1&size=3').then((res) => {
                if (res.data.code === '0000') {
                    this.$nextTick(() => {
                        this.love_list = res.data.content.integral_articles;
                        this.over('精选文章', this.love_list)
                    })
                }
            })
        },
        methods: {

            articleList(title) {
                this.$axios.get('miniprogram/articleList?page=1&size=10&title=' + title).then((res) => {
                    if (res.data.code === '0000') {
                        this.article_list = res.data.content.integral_articles.reverse();

                        // this.page=
                    }
                }, (err) => {
                    // alert(err)
                    throw new Error(err)
                });
            },
            over(name, list) {
                let obj = {};
                obj.list = list;
                obj.name = name;
                this.list_manage.push(obj)
            },
            ensureLove(item) {
                // api/miniprogram/likeArticle
                // api/miniprogram/cancelLikeArticle
                if (item.is_liked === 0) {
                    this.bg = '#ff464a';
                    this.$axios.post('miniprogram/likeArticle', {
                        'article_id': item.id
                    }).then((res) => {
                        if (res.data.code === '0000') {
                            this.$set(item, 'is_liked', 1)
                            Toast(res.data.content)
                        }
                    })
                } else {
                    this.bg = '#dcdcdc';
                    this.$axios.post('miniprogram/cancelLikeArticle', {
                        'article_id': item.id
                    }).then((res) => {
                        if (res.data.code === '0000') {
                            this.$set(item, 'is_liked', 0)
                            Toast(res.data.content)
                        }
                    })
                }

            },
            turnTo(article_id, index) {
                this.$router.push('detail?article_id=' + article_id)
            }
        }
    }
</script>

<style scoped>
    * {
        padding: unset;
        margin: unset;
    }

    .topic {
        height: 100%;
        overflow: auto;
        /*background: #f2f2f2;*/
        background: white;
    }

    .search {
        background-image: url("../assets/board.jpg");
        position: relative;
    }

    .title {
        font-family: 'SimSong', sans-serif;color:black;
        font-weight: bold;
    }

    .van-search__content {
        background: white;
    }

    .topic-top {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        /*background-image: url('../assets/color.jpg');*/
        background-color: rgba(255, 255, 255, .5);
        /*background-repeat: no-repeat;*/
        /*background-size:100% 100%;*/
        /*-webkit-filter: blur(10px) brightness(110%);*/
        /*filter: blur(10px) brightness(110%);*/
        z-index: -1;
    }

    .list {

    }

    .topic-article {
        padding: 0 .2rem .2rem;

    }

    .topic-select {
        flex-shrink: 0;

        overflow: hidden;
        position: relative;
        background: #fafafa;
        height: 2.8rem;
        width: 3.5rem;
        margin: .3rem .2rem .3rem 0;
        font-size: .28rem;
        border-radius: .15rem;
    }

    .select-top {
        width: 100%;
        height:1.8rem;
        overflow: auto;
    }

    .topic-card {
        border-radius: .2rem;
        margin-bottom: .2rem;
        background: transparent;
        box-shadow: 0 2px 3px #ebebeb;
    }

    .text-resolve {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text-resolve1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
